import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import SekoManual from "../static/seko.pdf"
import Toptitle from "../components/toptitle"
import styled from "styled-components"

const Construct = () => {
  return (
    <Layout>
      <SEO title="施工について" />
      <div>
        <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
          <Toptitle>Construct</Toptitle>
          <div className="pt-32 lg:mt-16 lg:pt-0">
            <h1 className="font-mincho text-3xl text-primary">施工について</h1>
          </div>
          <div className="my-16 w-full bg-secondery">
            <div className="justify-center lg:flex">
              <div className="w-full text-center lg:w-2/3 lg:text-left">
                <p className="py-16 px-8 font-gothic leading-loose">
                  詳しい施工方法や仕上げ、補修方法などをまとめた
                  <br />
                  施工要領書はPDFでダウンロードが可能です。
                </p>
              </div>
              <MobileButtonWrapper className="my-auto text-center lg:w-1/2">
                <a href={`${SekoManual}`} download="seko.pdf">
                  <button className="rounded border border-primary py-4 px-8 font-mincho text-primary hover:border-primaryHover hover:bg-primaryHover hover:text-white">
                    施工要領書のダウンロード
                  </button>
                </a>
              </MobileButtonWrapper>
            </div>
          </div>
          <div className="mt-8 w-full leading-loose">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>施工について</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  ・モールディング、見切り等
                  <br />
                  PB下地にボンド・ビス・タッカーにて固定します。
                  <br />
                  <br />
                  ※製品重量により下地金物が必要とします。
                  <br />
                  詳しくはお問い合わせください。
                  <br />
                  <br />
                  ・パネル等
                  <br />
                  PB下地にボンド・両面テープ・ビス・タッカーにて固定します。
                  <br />
                  コニシ株式会社TM工法を推奨します。
                  <br />
                  <br />
                  ※弊社では20mm以上の厚みのパネル及び天井面への施工には、
                  ビス併用を推奨しております。
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>基材の加工・ビス打ちについて</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  人工木材は木材と同じように加工が可能です。
                  <br />
                  丸ノコ（木用の刃）・手ノコで容易に切断可能です。
                  <br />
                  <br />
                  <br />
                  切断時、ケイカル質の為粉が出ます。
                  <br />
                  マスク等を使用して作業を行って下さい。
                  <br />
                  <br />
                  <br />
                  ビス打ち時は必ず下穴を先に空けてからのビス留めを行って下さい。
                  <br />
                  基材データ上のビス引っ張り強度は高い数値が出ておりますが、
                  <br />
                  強く廻しすぎて空回りした場合はビスの利きが極度に落ちます。
                  <br />
                  ビス留めを行う場合は十分にご注意下さい。
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>仕上げについて</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  製品の表面に塗装・クロス・左官仕上げ等が可能です。
                  <br />
                  出荷時には全面下地処理を行っておりますが、仕上げを行う際は
                  <br />
                  再度浸透性シーラーの塗布を行い、仕上げ施工をお願いします。
                  <br />
                  <br />
                  推奨塗料
                  <br />
                  ・合成エマルジョン塗料（EP）
                  <br />
                  ・アクリルエマルジョン塗料（AEP）
                  <br />
                  ※ラッカー系塗料・クリア塗料（シーラーが変色するため）は非推奨です。
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>補修について</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  小さな傷等は補修用パテをご利用ください。
                  <br />
                  大きな傷や欠損は一部切り取り、同形状の貼り付けにて対応ください。
                  <br />
                  <br />
                  推奨パテ
                  <br />
                  ・一発パテ（関西パテ化工株式会社）
                  <br />
                  <br />
                  ※パテは水分を含むため、若干のヤセが生じます。
                  <br />
                  2-3回程度繰り返し行うときれいに仕上がります。
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>製品の輸送および荷姿について</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  チャーター便でお送りします。
                  <br />
                  製品が少量の場合は宅急便を使用します。
                  <br />
                </p>
                <div className="mt-8 md:flex">
                  <div className="mx-auto w-1/2 max-w-xs">
                    <span className="mb-2 block">宅急便：ダンボール梱包</span>
                    <StaticImage
                      src="../images/construct/wrap1.jpg"
                      alt="ダンボール梱包"
                    />
                  </div>
                  <div className="mx-auto w-1/2 max-w-xs">
                    <span className="mb-2 block">
                      チャーター便：パレット梱包
                    </span>
                    <StaticImage
                      src="../images/construct/wrap2.jpg"
                      alt="パレット梱包"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="w-full font-gothic">
              <div className="bg-primary  py-4 px-8 text-white">
                <h2>製品の運搬について</h2>
              </div>
              <div className="border border-primary p-8">
                <p>
                  パネルの運搬は板面を垂直にし二人で運んでください。
                  <br />
                  板面を水平にすると、たわみや振動で破損の恐れがあります。
                  <br />
                  長尺のモールディングや見切りなどは中央付近で両手でお持ちください。
                  <br />
                </p>
                <div className="mt-8 flex">
                  <div className="w-1/2 max-w-xs">
                    <span className="mb-2 block">モールディング・見切り</span>
                    <StaticImage
                      src="../images/construct/carry1.svg"
                      alt="ダンボール梱包"
                    />
                  </div>
                  <div className="ml-4 w-1/2 max-w-xs">
                    <span className="mb-2 block">パネル</span>
                    <StaticImage
                      src="../images/construct/carry2.svg"
                      alt="パレット梱包"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

const MobileButtonWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    padding-bottom: 16px;
  }
`

export default Construct
